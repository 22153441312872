import React from 'react';
import { st, classes } from './Sidebar.st.css';
import { MainComponents } from '../../types';
import { RenderLocationProvider } from '../useRenderLocation';
import { componentSettings } from '../../componentSettings';
import { useSettings } from '@wix/tpa-settings/dist/src/hooks';

type SidebarProps = {
  width: string;
  className?: string;
};

const SidebarComponent: React.FC<SidebarProps> = ({
  width,
  className,
  children,
}) => {
  const settings = useSettings();
  return (
    <RenderLocationProvider value={MainComponents.SIDEBAR}>
      <div
        style={{ flexBasis: width }}
        className={st(
          classes.root,
          { sticky: settings.get(componentSettings.sidebarFreezePosition) },
          className,
        )}
      >
        {children}
      </div>
    </RenderLocationProvider>
  );
};

export default SidebarComponent;
