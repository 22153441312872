import React from 'react';
import { translate, InjectedTranslateProps } from 'yoshi-flow-editor-runtime';
import { PolicySectionViewModel } from '../../../../service-page-view-model/policy-section-view-model/policySectionViewModel';
import Section from '../Section';
import { SectionTypes } from '../../types';
import SectionTitle from '../SectionTitle/SectionTitle';
import SectionText from '../SectionText/SectionText';
import { componentSettings } from '../../componentSettings';
import { useSettings } from '@wix/tpa-settings/dist/src/hooks';

export type PolicyProps = InjectedTranslateProps & {
  viewModel: PolicySectionViewModel;
  className?: string;
};

const PolicyComponent = ({ t, viewModel, className }: PolicyProps) => {
  const settings = useSettings();
  const policySectionTitle = () => (
    <SectionTitle
      title={
        settings.get(componentSettings.policySectionTitle) ||
        t('app.settings.sections.sub-settings.policy.default.title')
      }
      dataHook="policy-title"
      className={className}
    />
  );

  const hasCancellationPolicy = !!viewModel.cancellationPolicy;

  const cancellationPolicy = () => (
    <SectionText
      text={viewModel?.cancellationPolicy}
      dataHook="cancellation-policy"
      className={className}
    />
  );

  return hasCancellationPolicy ? (
    <Section sectionType={SectionTypes.POLICY} data-hook="policy-section">
      <div>
        {policySectionTitle()}
        {cancellationPolicy()}
      </div>
    </Section>
  ) : null;
};

export const Policy = translate()(PolicyComponent);
