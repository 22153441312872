import React from 'react';
import { st, classes } from './Body.st.css';
import { AlignmentOptions, MainComponents } from '../../types';
import { RenderLocationProvider } from '../useRenderLocation';

type BodyProps = {
  width: string;
  bodyAlignment: AlignmentOptions;
  className?: string;
};

const BodyComponent: React.FC<BodyProps> = ({
  width,
  bodyAlignment,
  className,
  children,
}) => {
  return (
    <RenderLocationProvider value={MainComponents.BODY}>
      <div
        style={{ flexBasis: width }}
        className={st(classes.root, { alignment: bodyAlignment }, className)}
      >
        {children}
      </div>
    </RenderLocationProvider>
  );
};

export default BodyComponent;
